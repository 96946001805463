
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: stakebox, hardware, wallet, hardware wallet, ledger */
    }
    <h1>{`Hardware Wallets`}</h1>
    <h3>{`Stakebox`}</h3>
    <p><img parentName="p" {...{
        "src": "https://talk.peercoin.net/uploads/default/original/2X/8/8581bbbb551a82aea92598a5aa93c4144e387317.png",
        "alt": "stakebox"
      }}></img></p>
    <p>{`Stakebox is a project by Peercoin Foundation and PiSupply to deliver cost and power efficient, user friendly set-top box able to run a blockchain node.
Stakebox is a plug and play Pi with the Peercoin wallet pre-installed.What is need is a keyboard and monitor to plug in to and you are ready to go.`}</p>
    <p><em parentName="p">{`StakeBox is a brand by Pi Supply, which is a world leading distributor of Raspberry Pi mini computers.`}</em></p>
    <h4>{`Installation`}</h4>
    <p>{`Beside ordering a pre-installed Stakebox from `}<a parentName="p" {...{
        "href": "https://www.stakebox.org/products/peercoin-stakebox"
      }}>{`PiSupply website`}</a>{` you can also make one yourself.`}</p>
    <p>{`What do you need:`}</p>
    <ul>
      <li parentName="ul">{`Raspberry Pi (2 or newer)`}</li>
      <li parentName="ul">{`image downloaded from files.peercoin.net`}</li>
      <li parentName="ul">{`an SD card (4GB +)`}</li>
      <li parentName="ul">{`some spare time`}</li>
    </ul>
    <p>{`Download the image from `}<a parentName="p" {...{
        "href": "https://files.peercoin.net/"
      }}>{`files.peercoin.net`}</a>{`, use the `}<a parentName="p" {...{
        "href": "http://etcher.io/"
      }}>{`Etcher`}</a>{` to load it to the SD card.`}</p>
    <p>{`Now follow the guide: `}<a parentName="p" {...{
        "href": "https://www.stakebox.org/blogs/learn/getting-started-with-peercoin-stakebox"
      }}>{`https://www.stakebox.org/blogs/learn/getting-started-with-peercoin-stakebox`}</a>{`.`}</p>
    <hr></hr>
    <h3>{`Ledger Peercoin Tutorial`}</h3>
    <h4>{`Ledger Live`}</h4>
    <p>{`If you wish to store Peercoin on the Ledger Nano or Ledger Blue, this tutorial will guide you through using the Ledger Live program`}</p>
    <p>{`Open the Ledger Live program and enter your password to unlock the wallet.  In order to store Peercoin, we first need to add the Peercoin wallet application to the Ledger device.  If this is the first time you are using a Ledger device, you will have to install the Bitcoin app first.  Follow the same steps to add the Bitcoin app, then do the same process for the Peercoin application.  Select Manager from the sidebar and make sure your device is connected, unlocked, and allows access when prompted by the Ledger Manager.`}</p>
    <p>{`Once this is done, you will be presented with a variety of apps that can be added.  Type in "Peercoin" to find the Peercoin app.  Click the "Install" button and you will be asked to confirm this on your Ledger device.  After a moment, the app will be done installing.  Click "Okay" to proceed.`}</p>
    <p>{`Once this is finished, the Peercoin app is now installed on your Ledger device.  Now we need to add an actual wallet account which we can do by clicking "Portfolio" to return to our starting screen.  Once you are on the main screen, click the "+" by the Accounts tab to add a new asset.`}</p>
    <p>{`This will open the menu where you can search for the asset account you would like to add.  Input "Peercoin" and click continue.  You will have to unlock your Ledger again and navigate to the Peercoin app.  To open it, press both buttons on the Ledger Nano device simultaneously.  Once this is done, Ledger Live will sync and you will have the option to name the newly created account.`}</p>
    <p>{`Click continue until everything is finalized and completed.  You now have the Peercoin app, and the account added to your Ledger product and wallet.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;