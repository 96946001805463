
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: fees, burn, burned, fixed */
    }
    <h1>{`Burned and Fixed Fees`}</h1>
    <p>{`Peercoin's fees are fixed at 0.01 PPC per kB and are naturally burned with each transaction. The ease of provably burning coins is in contrast to many Proof-of-Work coins that recycle their transaction fees to miners in order to prepare for deflationary economies. Recycling fees back to block producers results in a 'fee market' that is inefficient and burdensome to the user experience. Peercon's economics allow for decentralized burning of a fixed fee when using the common resource of the Peercoin blockchain. As a result, block size limitations that plague other cryptocurrencies are not relevant to Peercoin. While the block size in Peercoin is set at 1 MB to protect against attacks, the community has been clear that higher blocksizes would be welcomed if the enhanced throughput were needed. As of writing, the Peercoin blockchain is very small (~1GB) and the fees are very low (~$0.005/txn), due in large part to the burned fixed-fee approach.`}</p>
    <p>{`Peercoin block producers are motivated to include transactions in a block because the reduction in supply from burning fees increases the relative value of their stake. Proof-of-Work has inherent issues with this concept because every bit of computation resource is needed to find a block, so addition of transactions must be motivated by recycled fees. In Proof-of-Stake, the computation required in order to mint is minimal such that including transactions comes at practically no cost to the block producer.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;