
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: paper, paper wallet, wallet, guide */
    }
    <h1>{`Peercoin Paper Wallet Guide`}</h1>
    <p>{`You can find the source code for this wallet here: `}<a parentName="p" {...{
        "href": "https://github.com/peercoin/peercoin-address-generator/"
      }}>{`https://github.com/peercoin/peercoin-address-generator/`}</a></p>
    <p>{`If you are interested in generating a paper wallet for holding your Peercoin, this guide will walk you through the process.  First, navigate to this address: `}<a parentName="p" {...{
        "href": "https://paperwallet.peercoin.net/"
      }}>{`https://paperwallet.peercoin.net/`}</a></p>
    <p><img parentName="p" {...{
        "src": "../img/paperwallet_mainscreen.JPG",
        "alt": "Paper Wallet Home Screen"
      }}></img></p>
    <p>{`To being, click the large green Start button.  This will begin the wallet generation process.  In order to ensure randomness, you will be asked to move your mouse cursor around for a period until a wallet address is generated.  This movement of the mouse adds entropy to the generation of an truly random address`}</p>
    <p><img parentName="p" {...{
        "src": "../img/paperwallet_random.JPG",
        "alt": "Swipe finger for randomness"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "../img/paperwallet_keepswiping.JPG",
        "alt": "Keep Swiping"
      }}></img></p>
    <p>{`Once you have moved the mouse enough, the wallet will be generated and you will be presented with the public and private key for the new wallet.`}</p>
    <p><img parentName="p" {...{
        "src": "../img/paperwallet_finished.JPG",
        "alt": "Finished!"
      }}></img></p>
    <p>{`To export the wallet, click the green share icon on the left side of the QR code.  You will then be presented with the options displayed below.`}</p>
    <p><img parentName="p" {...{
        "src": "../img/paperwallet_savingoptions.JPG",
        "alt": "Saving Options"
      }}></img></p>
    <p><em parentName="p">{`Save .txt locally
`}</em>{`This option will download the wallet as a text file.*
`}<em parentName="p">{`Copy address/priv. key to clipboard
`}</em>{`This option will copy the address/priv. key to a clipboard where it can be pasted.  Make sure your system is clean and secure before exposing this information to potential threats like keystroke loggers.*
`}<em parentName="p">{`Send via email
`}</em>{`This option will allow you to send the wallet information via email.*
`}<em parentName="p">{`Save JSON data will
`}</em>{`This option will download the wallet information in JSON format.*
`}<em parentName="p">{`Paper Wallet (print)
`}</em>{`This option will open a print dialogue from which the wallet can be printed or saved as a PDF.  Paper wallets provide physical security and backups for Peercoins that are to be left in cold storage.*`}</p>
    <p>{`This concludes the tutorial on the paper wallet generator.  This platform provides extra security for those who wish to keep their Peercoin in a safe and physical location.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;