
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: consensus, distribution, proof-of-stake, proof-of-work  */
    }
    <h1>{`Consensus algorithm`}</h1>
    <p>{`Peercoin is secured by Proof-of-Stake consensus. Proof-of-Work uses computational power as a scarce resource as a means of selecting the producer of a block, while Proof-of-Stake uses time continuously invested in the system measured in "coin age". Once an output to a transaction has reached an age of 30 days, the coins at this output become mature and are eligible for participation in the consensus process. Mature outputs are used as proof of having a stake in the system and are presented as evidence through the "coinstake" transaction of a new block. Each second of the timestamp is used as the nonce in the block hashing function to try to achieve the target difficulty. In conjunction with the number of coins, the probability of minting is proportional to the number of days since the 30 day mark with a maximum of 90 days, at which point the output has reached maximum maturity. Upon minting, the output will gain a block reward that is linearly related to the coinage, and the stake used to mint will be locked for 520 blocks (about 3 days). Minting can be done on almost any computer with the standard client installed, including compact computers like raspberry pi's, making the process objectively energy efficient when compared with computational hash-based proof algorithms. Simply put, Peercoin evens out the race between high and low power computers by limiting the number of hashes you can perform to 1 per second.`}</p>
    <p>{`The Proof-of-Stake mechanism requires attackers to be monetarily invested in the system. They must undergo a good bit of opportunity cost as well as risking a large amount of stake in order to manipulate the consensus maliciously. In Proof-of-Work, any coordinated collection of powerful computers is a threat to the system. In Proof-of-Stake, any would-be attacker is conflicting with their own interests by attacking a system they are invested in. Beyond this base-level trust mechanism, giving the holders of the coins direct say over the protocol used to secure their stake generates more direct engagement between the the code and its users. The self-sufficiency of Peercoin is the ability to govern itself through an efficient consensus model that consolidates power in the hands of the users.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;