
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: block size, size, time, spacing */
    }
    <h1>{`Block size limit and block time spacing`}</h1>
    <p>{`We will now compare Peercoin's network parameters directly with Bitcoin and Ethereum, which are vastly different blockchains.
Bitcoin features the 1MB block size limits which serves to place the upward pressure on the transaction fee price, Peercoin copies this parameter from the Bitcoin but it's economic model does not depend on scarcity of the block space.
While block generation is a stochastic process, each chain is targeted to generate blocks based on a real time interval. Bitcoin generates a block every 10 minutes, akin to Peercoin PoS block generation, while Ethereum generates a block every 12 seconds.  Peercoin PoW block generation is targeted at 1 hour, such that the total block time of Peercoin can be empirically estimated to be 8.57 minutes. Considering this and the block size limit of 1MB, Bitcoin has a transaction per second (TPS) of about 7 tps, Peercoin has an estimated 8 tps, and Ethereum has about 25 tps. However, it should be understood that Peercoin has never hit this transaction rate consistently, and the block size limit would very likely be raised if this were to happen.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;