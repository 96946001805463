
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: proof-of-work, pow, mining */
    }
    <h1>{`Mining`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Peercoin uses both the Proof-of-Work and Proof-of-Stake algorithms. The PoW algorithm is used to spread the distribution of new coins. Up to 99% of all peercoins is created with PoW. Proof-of-Stake is used to secure the network: The chain with longest PoS coin age wins in case of a blockchain split-up.`}</p>
    </blockquote>
    <p>{`Peercoin uses the hashcash double iterated SHA-256 algorithm for `}<a parentName="p" {...{
        "href": "https://en.bitcoin.it/wiki/Proof_of_work"
      }}>{`Proof-of-Work mining`}</a>{`. This means that any hardware that can mine Bitcoin can mine Peercoin as well.`}</p>
    <p>{`To mine Peercoin, you need a mining software. Below is a list that is not official endorsed but have been found to have a decent reputation.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://bfgminer.org/"
        }}>{`BFGMiner`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/ckolivas/cgminer"
        }}>{`CGMiner`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://easyminer.net/"
        }}>{`EasyMiner`}</a></li>
    </ul>
    <p>{`There are others, but this list can be used as a starting place.  Each will request pool or solo information and should come with the related support.`}</p>
    <h2>{`Peercoin Mining Pools`}</h2>
    <p>{`Peercoin network hashrate is too high to expect that solo-mining will work. It is recommended to join a mining pool.
You can find the list of mining pools at the following websites:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wheretomine.io/coins/peercoin/"
        }}>{`WhereToMine`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://miningpoolstats.stream/peercoin"
        }}>{`MiningPoolStats`}</a></li>
    </ul>
    <p>{`Both lists are not verified or vetted, please do your own research before joining some pool.`}</p>
    <h2>{`Peercoin Mining Profitability`}</h2>
    <p>{`If you want to calculate the profitability of mining Peercoin, you can use this website: `}<a parentName="p" {...{
        "href": "https://www.coinwarz.com/calculators/peercoin-mining-calculator"
      }}>{`https://www.coinwarz.com/calculators/peercoin-mining-calculator`}</a></p>
    <h2>{`Mining Confirmations`}</h2>
    <p>{`Once a block has been mined, 520 blocks must be passed for the mining to be confirmed. This is roughly 3.61 days of time.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;