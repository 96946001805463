
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: compile, build, compiling, debian, packaging */
    }
    <h1>{`Compiling`}</h1>
    <h3>{`Compiling packages for Debian and Ubuntu`}</h3>
    <p>{`As Peercoin is made to run on range of platforms, from Amazon's server to low powered Raspberry Pi Debian is perfect OS platform for deploying Peercoin nodes as it is renowned for multitude of supported hardware architectures as well as security and stability.`}</p>
    <p>{`For compilation of Debian packages we will be using `}<inlineCode parentName="p">{`pbuilder`}</inlineCode>{` which is a automatic Debian Package Building system for personal development workstation environments.`}<sup><a parentName="p" {...{
          "href": "#footnote-3.1"
        }}>{`3.1`}</a></sup>{` pbuilder aims to be an easy-to-setup system for auto-building Debian packages inside a clean-room environment, so that it is possible to verify that a package can be built on most Debian installations. The clean-room environment is achieved through the use of a base chroot image, so that only minimal packages will be installed inside the chroot.`}</p>
    <p>{`The following tutorial is written for the Ubuntu or Debian host, precisely Ubuntu 17.10 which is last stable version of Ubuntu at the time of writing.`}</p>
    <h3>{`Prepare the tooling`}</h3>
    <p><inlineCode parentName="p">{`sudo apt install pbuilder qemu-user-static ubuntu-keyring debian-archive-keyring`}</inlineCode></p>
    <p>{`We'll also need keyring for the Raspbian, which a Debian fork made for the Raspberry Pi platform, so we can compile packages for this ARM based mini-pc platform.`}</p>
    <blockquote>
      <p parentName="blockquote">{`wget `}<a parentName="p" {...{
          "href": "http://archive.raspbian.org/raspbian/pool/main/r/raspbian-archive-keyring/raspbian-archive-keyring_20120528.2_all.deb"
        }}>{`http://archive.raspbian.org/raspbian/pool/main/r/raspbian-archive-keyring/raspbian-archive-keyring_20120528.2_all.deb`}</a></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`sudo dpkg -i raspbian-archive-keyring_20120528.2_all.deb`}</p>
    </blockquote>
    <p>{`Now, set up the conf file for the pbuilder.`}</p>
    <p><inlineCode parentName="p">{`touch .pbuilderrc`}</inlineCode></p>
    <blockquote>
      <p parentName="blockquote">{`gedit .pbuilderrc`}</p>
    </blockquote>
    <p>{`Paste the following:`}</p>
    <pre><code parentName="pre" {...{}}>{`#!/bin/sh

set -e

PBUILDERSATISFYDEPENDSCMD="/usr/lib/pbuilder/pbuilder-satisfydepends-apt"

if [ "$OS" == "debian" ]; then
    MIRRORSITE="http://ftp.no.debian.org/debian/"
    COMPONENTS="main contrib non-free"
    DEBOOTSTRAPOPTS=("\${DEBOOTSTRAPOPTS[@]}"
        "--keyring=/usr/share/keyrings/debian-archive-keyring.gpg")
    : \${DIST:="wheezy"}
    : \${ARCH:="amd64"}
    if [ "$DIST" == "wheezy" ]; then
        #EXTRAPACKAGES="$EXTRAPACKAGES debian-backports-keyring"
        OTHERMIRROR="$OTHERMIRROR | deb $MIRRORSITE wheezy-backports $COMPONENTS"
    fi
elif [ "$OS" == "raspbian" ]; then
    MIRRORSITE="http://ftp.acc.umu.se/mirror/raspbian/raspbian/"
    COMPONENTS="main contrib non-free"
    DEBOOTSTRAPOPTS=("\${DEBOOTSTRAPOPTS[@]}"
        "--keyring=/usr/share/keyrings/raspbian-archive-keyring.gpg")
    : \${DIST:="wheezy"}
    : \${ARCH:="armhf"}
elif [ "$OS" == "ubuntu" ]; then
    MIRRORSITE="http://no.archive.ubuntu.com/ubuntu/"
    COMPONENTS="main restricted universe multiverse"
    DEBOOTSTRAPOPTS=("\${DEBOOTSTRAPOPTS[@]}"
        "--keyring=/usr/share/keyrings/ubuntu-archive-keyring.gpg")
else
    echo "Unknown OS: $OS"
    exit 1
fi

if [ "$DIST" == "" ]; then
    echo "DIST is not set"
    exit 1
fi

if [ "$ARCH" == "" ]; then
    echo "ARCH is not set"
    exit 1
fi

NAME="$OS-$DIST-$ARCH"

if [ "$ARCH" == "armel" ] && [ "$(dpkg --print-architecture)" != "armel" ]; then
    DEBOOTSTRAP="qemu-debootstrap"
fi
if [ "$ARCH" == "armhf" ] && [ "$(dpkg --print-architecture)" != "armhf" ]; then
    DEBOOTSTRAP="qemu-debootstrap"
fi

DEBOOTSTRAPOPTS=("\${DEBOOTSTRAPOPTS[@]}" "--arch=$ARCH")
BASETGZ="/var/cache/pbuilder/$NAME-base.tgz"
DISTRIBUTION="$DIST"
BUILDRESULT="$HOME/pbuild-results/"
APTCACHE="/var/cache/pbuilder/$NAME/aptcache/"
BUILDPLACE="/var/cache/pbuilder/build"
HOOKDIR="/var/cache/pbuilder/hook.d/"
`}</code></pre>
    <p>{`Make the directory where pbuilder will place the packages:`}</p>
    <p><inlineCode parentName="p">{`mkdir -p $HOME/pbuild-results`}</inlineCode></p>
    <h3>{`Bootstrap the chroots`}</h3>
    <p>{`Raspbian (buster):`}</p>
    <blockquote>
      <p parentName="blockquote">{`sudo OS=raspbian DIST=buster ARCH=armhf pbuilder --create`}</p>
    </blockquote>
    <p>{`Debian stable:`}</p>
    <blockquote>
      <p parentName="blockquote">{`sudo OS=debian DIST=buster ARCH=amd64 pbuilder --create`}</p>
    </blockquote>
    <h3>{`Preparing for build`}</h3>
    <p>{`(compiling for Raspbian stretch in this example)`}</p>
    <p>{`Download the latest .tar.gz from github.com/peercoin.`}</p>
    <blockquote>
      <p parentName="blockquote">{`wget `}<a parentName="p" {...{
          "href": "https://github.com/peercoin/peercoin/releases/download/v0.11.0ppc/peercoin-0.11.0.tar.gz"
        }}>{`https://github.com/peercoin/peercoin/releases/download/v0.11.0ppc/peercoin-0.11.0.tar.gz`}</a></p>
    </blockquote>
    <p>{`Debian build system is very strict about names, so we need to rename this to:`}</p>
    <p><inlineCode parentName="p">{`peercoin_0.11.0.orig.tar.gz`}</inlineCode></p>
    <p>{`Extract the contests of the file using `}<inlineCode parentName="p">{`tar xf`}</inlineCode>{` and `}<inlineCode parentName="p">{`cd`}</inlineCode>{` to it.`}</p>
    <p>{`Copy the debian directory from the contrib to this directory:`}</p>
    <p><inlineCode parentName="p">{`cp -r contrib/debian .`}</inlineCode></p>
    <h4>{`Some modifications`}</h4>
    <p>{`(this is just an extra step required for the Raspbian, to fix problem with autotools)`}</p>
    <p><inlineCode parentName="p">{`sed '/./configure --with-gui=qt5 --with-incompatible-bdb/s/$/ --with-boost-libdir=/usr/lib/arm-linux-gnueabihf/' debian/rules`}</inlineCode></p>
    <h3>{`Building the package`}</h3>
    <p><inlineCode parentName="p">{`OS=raspbian DIST=buster ARCH=armhf pdebuild`}</inlineCode></p>
    <p>{`And wait, it will take a while so go get a coffee or something. It's compiling by emulating ARM cpu in QEMU.`}</p>
    <p>{`The concept of pbuild and cross-platform compilations is that you pass it this environment variables like "OS" and "DIST".`}</p>
    <p>{`For example OS=debian and DIST=wheezy will use Debian Wheezy chroot, you can also pick architecture by using ARCH= environment variable.`}</p>
    <h3>{`Footnotes`}</h3>
    <a id="footnote-3.1">3.1</a>: https://jodal.no/2015/03/08/building-arm-debs-with-pbuilder/
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;