
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: developer, notes, mainnet, testnet, port, wif, magic, bytes */
    }
    <h1>{`Peercoin Developer Notes`}</h1>
    <p>{`Constants that may be useful when looking to integrate / develop with Peercoin.`}</p>
    <p>{`Peercoin source code repository: `}<a parentName="p" {...{
        "href": "https://github.com/peercoin/peercoin"
      }}>{`https://github.com/peercoin/peercoin`}</a></p>
    <h3>{`Mainnet`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`p2pkh Base58 prefix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`p2sh Base58 prefix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`p`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`p2pkh Base58 prefix (hex)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x37`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`p2sh Base58 prefix (hex)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x75`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Magic bytes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`\\xe6\\xe8\\xe9\\xe5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`WIF prefix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0xb7`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Genesis hash hex (big-endian)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0000000032fe677166d54963b62a4677d8957e87c508eaa4fd7eb1c880cd27e3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Genesis hash bytes (little-endian)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`\\xe3\\x27\\xcd\\x80\\xc8\\xb1\\x7e\\xfd\\xa4\\xea\\x08\\xc5\\x87\\x7e\\x95\\xd8\\x77\\x46\\x2a\\xb6\\x63\\x49\\xd5\\x66\\x71\\x67\\xfe\\x32\\x00\\x00\\x00\\x00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Genesis tx hash hex`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3c2d8f85fab4d17aac558cc648a1a58acff0de6deb890c29985690052c5993c2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Genesis tx hash bytes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`\\xc2\\x93\\x59\\x2c\\x05\\x90\\x56\\x98\\x29\\x0c\\x89\\xeb\\x6d\\xde\\xf0\\xcf\\x8a\\xa5\\xa1\\x48\\xc6\\x8c\\x55\\xac\\x7a\\xd1\\xb4\\xfa\\x85\\x8f\\x2d\\x3c`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Default port`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9901`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Default RPC port`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9902`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BIP44 coin type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x80000006`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`bech32 prefix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`pc`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Testnet`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`p2pkh Base58 prefix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m or n`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`p2sh Base58 prefix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`n`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`p2pkh Base58 prefix (hex)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x6f`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`p2sh Base58 prefix (hex)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0xc4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Magic bytes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`\\xcb\\xf2\\xc0\\xef`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`WIF prefix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0xef`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Genesis hash hex (big-endian)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`00000001f757bb737f6596503e17cd17b0658ce630cc727c0cca81aec47c9f06`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Genesis hash bytes (little-endian)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`\\x06\\x9f\\x7c\\xc4\\xae\\x81\\xca\\x0c\\x7c\\x72\\xcc\\x30\\xe6\\x8c\\x65\\xb0\\x17\\xcd\\x17\\x3e\\x50\\x96\\x65\\x7f\\x73\\xbb\\x57\\xf7\\x01\\x00\\x00\\x00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Genesis tx hash hex`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3c2d8f85fab4d17aac558cc648a1a58acff0de6deb890c29985690052c5993c2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Genesis tx hash bytes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`\\xc2\\x93\\x59\\x2c\\x05\\x90\\x56\\x98\\x29\\x0c\\x89\\xeb\\x6d\\xde\\xf0\\xcf\\x8a\\xa5\\xa1\\x48\\xc6\\x8c\\x55\\xac\\x7a\\xd1\\xb4\\xfa\\x85\\x8f\\x2d\\x3c`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Default port`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9903`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Default RPC port`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9904`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BIP44 coin type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x80000006`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`bech32 prefix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tpc`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Transaction format`}</h2>
    <p>{`Peercoin transaction format is indendical to a Bitcoin transaction format with the exception of included transaction timestamp.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Bytes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Version`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`transaction version number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`transaction timestamp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Input-counter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number of transaction inputs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1-9`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`List of inputs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`list of transaction inputs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`varies`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Output-counter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number of transaction outputs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1-9`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`List of outputs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`list of transaction outputs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`varies`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Locktime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`block number or Unix timestamp when the transaction finalizes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`As of Peercoin 0.11, timestamp is no longer required and transaction format is exactly the same as Bitcoin. The only exception is that `}<inlineCode parentName="p">{`version`}</inlineCode>{` is now `}<inlineCode parentName="p">{`3`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Bytes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Version`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`transaction version number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Input-counter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number of transaction inputs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1-9`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`List of inputs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`list of transaction inputs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`varies`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Output-counter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number of transaction outputs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1-9`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`List of outputs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`list of transaction outputs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`varies`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Locktime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`block number or Unix timestamp when the transaction finalizes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;