
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: changelog, protocol, changes, log */
    }
    <h1>{`Protocol versions (changelog)`}</h1>
    <p>{`Peercoin protocol versions do not represent Peercoin client versions, however client hosted at `}<a parentName="p" {...{
        "href": "http://www.github.com/peercoin/peercoin"
      }}>{`www.github.com/peercoin/peercoin`}</a>{` is considered Peercoin reference implementation.
Peercoin protocol version are marked using the following format:`}</p>
    <p><inlineCode parentName="p">{`v{major_version}.{minor_version}`}</inlineCode></p>
    <p>{`where:`}</p>
    <ul>
      <li parentName="ul">{`v is prefix (stands for "version")`}</li>
      <li parentName="ul">{`major_version is for capital improvements to the core concepts or complete redesigns`}</li>
      <li parentName="ul">{`minor_version is for evolutions of the protocol defined in the original whitepaper`}</li>
    </ul>
    <h2>{`v0.6`}</h2>
    <blockquote>
      <p parentName="blockquote">{`released: 25.10.2017`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`type: softfork`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`BIP34: block height in coinbase`}</li>
      <li parentName="ul">{`BIP65: OP_CHECKLOCKTIMEVERIFY`}</li>
    </ul>
    <h2>{`v0.7`}</h2>
    <blockquote>
      <p parentName="blockquote">{`relased: 22.01.2019`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`type: hardfork`}</p>
    </blockquote>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/peercoin/rfcs/blob/master/text/0007-round-transaction-fees-up-to-0.001/0007-round-transaction-fees-up-to-0.001.md"
        }}>{`rfc-0007`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/peercoin/rfcs/blob/master/text/0008-increase-op-return-size-limit/0008-increase-op-return-size-limit.md"
        }}>{`rfc-0008`}</a></li>
    </ul>
    <h2>{`v0.8`}</h2>
    <blockquote>
      <p parentName="blockquote">{`relased: 29.07.2019`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`type: hardfork`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`rebase to bitcoin-core 0.16.3`}</li>
      <li parentName="ul">{`Compact blocks support (BIP152) with upgraded protocol version to 70015`}</li>
      <li parentName="ul">{`HD wallet support (BIP32)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/peercoin/rfcs/blob/master/text/0006-remove-pow-block-signature/0006-remove-pow-block-signature.md"
        }}>{`rfc-0006`}</a></li>
      <li parentName="ul">{`mainnet fork is scheduled for 1st of October 2019, activating BIPS 62, 68, 112, 113 and 141`}</li>
    </ul>
    <h2>{`v0.9`}</h2>
    <blockquote>
      <p parentName="blockquote">{`released: 15.05.2020`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`type: hardfork`}</p>
    </blockquote>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/peercoin/rfcs/blob/master/text/0019-pow-block-spacing/0019-pow-block-spacing.md"
        }}>{`RFC-0019`}</a>{`: PoW Block Spacing`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/peercoin/rfcs/blob/master/text/0018-pos-reward/0018-pos-reward.md"
        }}>{`RFC-0018`}</a>{`: PoS Rewards Adjustment`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/peercoin/rfcs/blob/master/text/0017-coinage-limit/0017-coinage-limit.md"
        }}>{`RFC-0017`}</a>{`: Limit Effective Coinage to One Year`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/peercoin/rfcs/blob/master/text/0015-time-drift/0015-time-drift.md"
        }}>{`RFC-0015`}</a>{`: Reduce Time Drift`}</li>
      <li parentName="ul">{`allow staking=0 command to disable minting (nominting=0 also works)`}</li>
      <li parentName="ul">{`ability to filter out mint transactions in the QT wallet`}</li>
    </ul>
    <h2>{`v0.10`}</h2>
    <blockquote>
      <p parentName="blockquote">{`released: 10.05.2021`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`type: softfork`}</p>
    </blockquote>
    <p>{`Rebased to latest Bitcoin-core codebase.`}</p>
    <h2>{`v0.11`}</h2>
    <blockquote>
      <p parentName="blockquote">{`released: 02.10.2021`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`type: hardfork`}</p>
    </blockquote>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/peercoin/rfcs/blob/master/text/0014-transaction-timestamp/0014-transaction-timestamp.md"
        }}>{`RFC-0014`}</a>{`: Remove Transaction Timestamp`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/peercoin/rfcs/blob/master/text/0022-pow-reward-cap/0022-pow-reward-cap.md"
        }}>{`RFC-0022`}</a>{`: Proof-of-Work reward cap`}</li>
      <li parentName="ul">{`make splitting coins during minting optional`}</li>
    </ul>
    <h2>{`v0.12`}</h2>
    <blockquote>
      <p parentName="blockquote">{`released: 01.02.2022`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`type: hardfork`}</p>
    </blockquote>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/peercoin/rfcs/blob/master/text/0005-unspendable-zero-outputs/0005-unspendable-zero-outputs.md"
        }}>{`RFC-0005`}</a>{`: Prunes zero value outputs from UTXO set.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/peercoin/rfcs/blob/master/text/0020-pow-difficulty-on-pos-blocks/0020-pow-difficulty-on-pos-blocks.md"
        }}>{`RFC-0020`}</a>{`: Reduces PoW difficulty on new PoS blocks if PoW block is overdue.`}</li>
      <li parentName="ul">{`Client caches coinstake input transactions to reduce disk access and improve responsiveness.`}</li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;