
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: press */
    }
    <h1>{`Press Mentions`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.yahoo.com/news/pioneer-proof-stake-peercoin-releases-213712981.html"
        }}>{`03-FEB-2023 - Yahoo - Peercoin Releases v0.12 Upgrade (Codename Ladybug)Peercoin Releases v0.12 Upgrade (Codename Ladybug)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.moneyshow.com/articles/newswire-60197/moneyshow-launches-newswire-service/"
        }}>{`01-JAN-2023 - MoneyShow - MoneyShow Launches Newswire Service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.investopedia.com/terms/p/peercoin.asp"
        }}>{`05-AUG-2021 - Investopedia - Peercoin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.bdratings.org/l/peercoin-an-old-tardigrade"
        }}>{`30-JAN-2019 - Bdratings - Peercoin — An Old Tardigrade`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bitfalls.com/2018/03/11/peercoin-explained-proof-stake-pioneer/"
        }}>{`11-MAR-2018 - Bitfalls - Peercoin Explained: The Proof of Stake Pioneer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://coinreport.net/little-altcoin-sanity-peercoin/"
        }}>{`19-JUL-2014 - CoinReport - A Little Altcoin Sanity: Peercoin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://cointrader.org/peercoin-proof-of-stake-and-bitcoin/"
        }}>{`19-JAN-2014 - CoinTrader - Could Peercoin and “Proof-of-Stake” Turn Bitcoin Into The Myspace of Cryptocurrency?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://hereisthecity.com/en-gb/2013/11/29/nine-bitcoin-alternatives-for-future-currency-investments/"
        }}>{`29-NOV-2013 - HITC - Nine bitcoin alternatives for future currency investments`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://motherboard.vice.com/blog/beyond-bitcoin-a-guide-to-the-most-promising-cryptocurrencies"
        }}>{`29-NOV-2013 - VICE Motherboard - Beyond Bitcoin: A Guide to the Most Promising Cryptocurrencies`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://dealbook.nytimes.com/2013/11/24/in-bitcoins-orbit-rival-virtual-currencies-vie-for-acceptance/?_r=1&"
        }}>{`24-NOV-2013 - New York Times - In Bitcoin’s Orbit: Rival Virtual Currencies Vie for Acceptance`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://live.wsj.com/video/fastcoin-peercoin-bitcoin-opens-door-to-new-currencies/F7798154-510D-4AF5-AC92-BE43F9289F56.html"
        }}>{`20-NOV-2013 - WSJ - Fastcoin? Peercoin? Bitcoin Opens Door to New Currencies`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.forbes.com/sites/karlwhelan/2013/11/20/so-whats-so-special-about-bitcoin-2/"
        }}>{`20-NOV-2013 - Forbes - So What's So Special About Bitcoin?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.cnbc.com/id/101189988/"
        }}>{`19-NOV-2013 - CNBC - Who needs bitcoin? Check out these virtual currency alternatives`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://geofftalk.com/?p=5595"
        }}>{`18-NOV-2013 - GeoffTalk - Crytocurrencies and Hayek’s Competing Currencies Have Come to Life`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.btc38.com/altcoin/general/285.html"
        }}>{`27-SEP-2013 - 比特时代 (btc38) - 网友点评分析：虚拟货币“八大金刚”排座次`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://bitcoinmagazine.com/6528/what-proof-of-stake-is-and-why-it-matters/"
        }}>{`26-AUG-2013 - Bitcoin Magazine - What Proof of Stake Is And Why It Matters`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://letstalkbitcoin.com/the-archetypes-of-virtual-currency/"
        }}>{`23-AUG-2013 - Let's Talk Bitcoin - The Archetypes of Virtual Currency`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://themisescircle.org/blog/2013/08/22/the-problem-with-altcoins/"
        }}>{`22-AUG-2013 - The Mises Circle - The Problem with Altcoins`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.coderblog.de/technical-basis-of-digital-currencies/"
        }}>{`02-AUG-2013 - Coder Blog - Technical Basis of Digital Currencies`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.maketecheasier.com/4-popular-bitcoin-alternatives/2013/07/29"
        }}>{`29-JUL-2013 - Make Tech Easier - 4 Popular Bitcoin Alternatives and How They Compare to Bitcoin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.coindesk.com/bitcoin-developer-jeff-garzik-on-altcoins-asics-and-bitcoin-usability/"
        }}>{`15-JUL-2013 - CoinDesk - Bitcoin developer Jeff Garzik on altcoins, ASICs and bitcoin usability`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://bitcoinmagazine.com/coinsetter-will-a-better-virtual-currency-make-bitcoin-obsolete/"
        }}>{`26-JUN-2013 - Bitcoin Magazine - Coinsetter: Will a Better Virtual Currency Make Bitcoin Obsolete?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.guardian.co.uk/technology/2013/jun/25/bitcoin-successors-litecoin-freicoin"
        }}>{`25-JUN-2013 - The Guardian - Bitcoin's successors: from Litecoin to Freicoin and onwards`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://themisescircle.org/blog/2013/06/24/the-proof-of-work-concept/"
        }}>{`24-JUN-2013 - The Mises Circle - The Proof-of-Work Concept`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.researchandmarkets.com/research/s5f5c3/alternative"
        }}>{`13-JUN-2013 - Mercator Advisory Group - Alternative Currencies: Is There Staying Power?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.dailydot.com/business/digital-currency-bitcoin-litecoin-ven-webmoney/"
        }}>{`10-JUN-2013 - The Daily Dot - Beyond Bitcoin: A guide to the new digital currencies`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.tomshardware.com/reviews/bitcoin-mining-make-money,3514-9.html"
        }}>{`09-JUN-2013 - Tom's Hardware - All About Bitcoin Mining: Road To Riches Or Fool's Gold?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.btcpedia.com/mine-ppcoin-with-pc/"
        }}>{`07-MAY-2013 - BTC Pedia - How To Mine PPCoin (PPC) With Your PC`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.wired.co.uk/news/archive/2013-05/7/alternative-cryptocurrencies-guide/viewall"
        }}>{`07-MAY-2013 - Wired UK - Wary of Bitcoin? A guide to some other cryptocurrencies`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://techcitement.com/software/digging-deeper-into-the-bitcoin-mine/"
        }}>{`06-MAY-2013 - Techcitement - Digging Deeper Into The Bitcoin Mine`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.golem.de/news/virtuelle-waehrungen-alternativen-zu-bitcoin-1304-98879.html"
        }}>{`23-APR-2013 - golem - Alternativen zu Bitcoin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://t3n.de/news/3-bitcoin-alternativen-litecoin-459549/"
        }}>{`23-APR-2013 - t3n - 3 Bitcoin-Alternativen: Litecoin, PPCoin und Namecoin im Überblick`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.geekpark.net/read/view/176900"
        }}>{`17-APR-2013 - Geek Park - 防贬值、抑通胀、低能耗——PPCoin 能超越 Bitcoin 吗？`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.nzz.ch/aktuell/digital/drei-alternativen-zu-bitcoin-1.18065217"
        }}>{`16-APR-2013 - Neue Zürcher Zeitung - Drei Alternativen zu Bitcoin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://goldresource.net/gold-vs-bitcoin"
        }}>{`15-APR-2013 - Gold Resource - Gold vs Bitcoin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.technologyreview.com/news/513661/bitcoin-isnt-the-only-cryptocurrency-in-town/"
        }}>{`15-APR-2013 - MIT Technology Review - Bitcoin Isn't the Only Cryptocurrency in Town`}</a></li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;