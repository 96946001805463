
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: bootstrap, wallet, bootstrapping */
    }
    <h1>{`Bootstrapping`}</h1>
    <h2>{`What is it?`}</h2>
    <blockquote>
      <p parentName="blockquote">{`In computer technology the term (usually shortened to booting) usually
refers to the process of loading the basic software into the memory of
a computer after power-on or general reset, especially the operating
system which will then take care of loading other software as needed.`}<sup><a parentName="p" {...{
            "href": "#footnote-9.1"
          }}>{`9.1`}</a></sup></p>
    </blockquote>
    <p>{`For Peercoin it means loading all of the block chain history from a special
file containing a snapshot of block data.`}</p>
    <p>{`This special file, named `}<inlineCode parentName="p">{`bootstrap.dat`}</inlineCode>{`, allows the Peercoin client to
sync from your hard drive instead of the internet. Using a
`}<inlineCode parentName="p">{`bootstrap.dat`}</inlineCode>{` file is faster and reduces stress on the Peercoin network to sync new nodes.`}</p>
    <h2>{`How do I make a `}<inlineCode parentName="h2">{`bootstrap.dat`}</inlineCode>{`?`}</h2>
    <p>{`Any synced client has the ability to make a `}<inlineCode parentName="p">{`bootstrap.dat`}</inlineCode>{` file. Assuming
you're running linux you can do the following to manufacture your own.
First, shutdown your client. Allow it to cleanly exit so we know the block
data is settled.`}</p>
    <p>{`Now, navigate to the directory `}<inlineCode parentName="p">{`~/.peercoin/blocks`}</inlineCode>{` in your terminal and
notice the files named `}<inlineCode parentName="p">{`blk00000.dat`}</inlineCode>{`, `}<inlineCode parentName="p">{`blk00001.dat`}</inlineCode>{`, `}<inlineCode parentName="p">{`blk00002.dat`}</inlineCode>{`, etc.
These are the raw block data files that can be combined to form the
`}<inlineCode parentName="p">{`bootstrap.dat`}</inlineCode>{`!`}</p>
    <p>{`The command:`}</p>
    <blockquote>
      <p parentName="blockquote">{`cat blk*.dat > bootstrap.dat`}</p>
    </blockquote>
    <p>{`will produce the `}<inlineCode parentName="p">{`bootstrap.dat`}</inlineCode>{`.
The file is often then compressed (zip'ed, tar/gzip'ed) and shared.`}</p>
    <p>{`The same process can be executed on Microsoft Windows (7+):`}</p>
    <blockquote>
      <p parentName="blockquote">{`CD C:\\Users\\<my_user>\\AppData\\Roaming\\Peercoin`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`COPY /b blk0001.dat+blk0002.dat bootstrap.dat`}</p>
    </blockquote>
    <p>{`Or on OS X:`}</p>
    <blockquote>
      <p parentName="blockquote">{`cd "~/Library/Application Support/Peercoin/"`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`cat blk*.dat > bootstrap.dat`}</p>
    </blockquote>
    <p>{`On linux and OS X you can create hash of the bootstrap:`}</p>
    <blockquote>
      <p parentName="blockquote">{`sha256 bootstrap.dat`}</p>
    </blockquote>
    <h2>{`How do I use a `}<inlineCode parentName="h2">{`bootstrap.dat`}</inlineCode>{`?`}</h2>
    <p>{`Assuming you're on linux, it should be very similar if not the same on the OSX.
First, decompress the bootstrap if it is compressed, then simply launch the node with adequate command:`}</p>
    <blockquote>
      <p parentName="blockquote">{`peercoind -loadblock=bootstrap.dat`}</p>
    </blockquote>
    <p>{`You should see the status `}<inlineCode parentName="p">{`Importing blocks from disk...`}</inlineCode>{` if the client has found the `}<inlineCode parentName="p">{`bootstrap.dat`}</inlineCode>{` and is using it to
sync the block chain.`}</p>
    <h2>{`Footnotes`}</h2>
    <a id="footnote-9.1">9.1</a>: https://en.wikipedia.org/wiki/Bootstrapping
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;