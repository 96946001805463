
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: json, rpc, api, reference */
    }
    <h1>{`JSON-RPC API reference`}</h1>
    <p>{`Peercoin daemon offers JSON-RPC interface which can be used to control the daemon or integrate it with software stack.
You can send commands to the daemon by using `}<inlineCode parentName="p">{`peercoin-cli`}</inlineCode>{` tool.`}</p>
    <p>{`There are two official wrappers for this interface, a PHP one and a Python2.7+ one.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Peercoin_rpc is a simple and minimal library made for communication with peercoind via JSON-RPC protocol. It has a single dependency - a Python requests library and it supports both mainnet and testnet peercoin network with authentication or SSL encryption. There is a single class to be imported from the library - Client.`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://github.com/peercoin/peercoin_rpc"
      }}>{`https://github.com/peercoin/peercoin_rpc`}</a></p>
    <blockquote>
      <p parentName="blockquote">{`peercoin-php-rpc is a simple and minimal library made for communication with peercoind via JSON-RPC protocol for PHP 7.1+. Easiest way to use is to use composer. Otherwise include RpcClient class in your project an you are good to go.`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://github.com/peercoin/peercoin-php-rpc"
      }}>{`https://github.com/peercoin/peercoin-php-rpc`}</a></p>
    <h3>{`List of JSON-RPC calls`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Command`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Requires unlocked wallet? (yes/no)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getinfo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns an object containing various state info.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getblock`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hash`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns information about the block with the given hash.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getblockcount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns the number of blocks in the longest block chain.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getblockhash`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`block_num`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns hash of block in best-block-chain at `}<inlineCode parentName="td">{`block_num`}</inlineCode>{`; 0 is the genesis block`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`gettransaction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`txid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns an object about the given transaction containing:`}<br />{`  "amount" : total amount of the transaction`}<br />{`"confirmations" : number of confirmations of the transaction`}<br />{`"txid" : the transaction ID`}<br />{`"time" : time associated with the transaction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`walletpassphrase`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`passphrase`}</inlineCode>{`   `}<inlineCode parentName="td">{`timeout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stores the wallet decryption key in memory for `}<inlineCode parentName="td">{`timeout`}</inlineCode>{` seconds.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getbalance`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[account][minconf=1]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If `}{`[account]`}{` is not specified, returns the server's total available balance.`}<br />{`If `}{`[account]`}{` is specified, returns the balance in the account.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getreceivedbyaddress`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`address`}</inlineCode>{` `}{`[minconf=1]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns the amount received by `}<inlineCode parentName="td">{`address`}</inlineCode>{` in transactions with at least `}{`[minconf]`}{` confirmations. It correctly handles the case where someone has sent to the address in multiple transactions. Keep in mind that addresses are only ever used for receiving transactions.`}<br />{`Works only for addresses in the local wallet, external addresses will always show 0.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getdifficulty`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns proof-of-stake and proof-of-work difficulty`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getpeerinfo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns data about each connected node.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getaddressesbyaccount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`account`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns the list of addresses for the given account.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getnewaddress`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[account]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns a new address for receiving payments.`}<br />{`If `}{`[account]`}{` is specified payments received with the address will be credited to `}{`[account]`}{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getaccount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns the account associated with the given `}<inlineCode parentName="td">{`address`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getaccountaddress`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`account`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns the current address for receiving payments to this account.`}<br />{`If `}<inlineCode parentName="td">{`account`}</inlineCode>{` does not exist, it will be created along with an associated new address that will be returned.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sendtoaddress`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`address`}</inlineCode>{` `}<inlineCode parentName="td">{`amount`}</inlineCode>{` `}{`[comment][comment-to]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`amount`}</inlineCode>{` is a real and is rounded to 6 decimal places. Returns the transaction ID `}<inlineCode parentName="td">{`txid`}</inlineCode>{` if successful.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sendfrom`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fromaccount`}</inlineCode>{` `}<inlineCode parentName="td">{`topeercoinaddress`}</inlineCode>{` `}<inlineCode parentName="td">{`amount`}</inlineCode>{` `}{`[minconf=1][comment]`}{` `}{`[comment-to]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`amount`}</inlineCode>{` is a real and is rounded to 6 decimal places. Will send the given amount to the given address, ensuring the account has a valid balance using `}{`[minconf]`}{` confirmations. Returns the transaction ID if successful (not in JSON object).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sendmany`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fromaccount`}</inlineCode>{` {address:amount,...} `}{`[minconf=1][comment]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`amounts are double-precision floating point numbers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getconnectioncount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns the number of connections to other nodes.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getrawtransaction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`txid`}</inlineCode>{` `}{`[verbose=0]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns raw transaction representation for given transaction id.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getrawmempool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns all transaction ids in memory pool.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`listtransactions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[account][count=10]`}{` `}{`[from=0]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns up to `}{`[count]`}{` most recent transactions skipping the first `}{`[from]`}{` transactions for account `}{`[account]`}{`. If `}{`[account]`}{` not provided it'll return recent transactions from all accounts.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`listreceivedbyaddress`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[minconf=1][includeempty=false]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns an array of objects containing:`}<br />{`"address" : receiving address`}<br />{`"account" : the account of the receiving address`}<br />{`"amount" : total amount received by the address`}<br />{`"confirmations" : number of confirmations of the most recent transaction included`}<br />{`To get a list of accounts on the system, execute `}<inlineCode parentName="td">{`peercoin-cli listreceivedbyaddress 0 true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`listreceivedbyaccount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[minconf=1][includeempty=false]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns an array of objects containing:`}<br />{`"account" : the account of the receiving addresses`}<br />{`"amount" : total amount received by addresses with this account`}<br />{`"confirmations" : number of confirmations of the most recent transaction included`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`listaccounts`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[minconf=1]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns Object that has account names as keys, account balances as values.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`listunspent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[minconf=1][maxconf=999999]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns array of unspent transaction inputs in the wallet.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dumpprivkey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reveals the private key corresponding to `}<inlineCode parentName="td">{`address`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`importprivkey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`privkey`}</inlineCode>{` `}{`[label][rescan=true]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Adds a private key (as returned by dumpprivkey) to your wallet. This may take a while, as a rescan is done, looking for existing transactions.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createrawtransaction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[{"txid":txid,"vout":n},...]`}{` {address:amount,...}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creates a raw transaction spending given inputs.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`decoderawtransaction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hex_string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Produces a human-readable JSON object for a raw transaction.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`signrawtransaction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hex_string`}</inlineCode>{` `}{`[{"txid":txid,"vout":n,"scriptPubKey":hex},...][\`privatekey1\`,...]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Adds signatures to a raw transaction and returns the resulting raw transaction.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`signmessage`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`address`}</inlineCode>{` `}<inlineCode parentName="td">{`message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sign a message with the private key of an address.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`verifymessage`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`address`}</inlineCode>{` `}<inlineCode parentName="td">{`signature`}</inlineCode>{` `}<inlineCode parentName="td">{`message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Verify a signed message.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sendrawtransaction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hex_string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Submits raw transaction (serialized, hex-encoded) to local node and network.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`validateaddress`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Return information about `}<inlineCode parentName="td">{`address`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`encryptwallet`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`passphrase`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Encrypts the wallet with `}<inlineCode parentName="td">{`passphrase`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`enforcecheckpoint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`enforce`}</inlineCode>{` is true or false to enable or disable enforcement of broadcasted checkpoints by developer.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`keypoolrefill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fills the key pool with new keys `}{`[default 100 new keys]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`listlockunspent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Returns list of temporarily unspendable outputs.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createmultisig`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nrequired`}</inlineCode>{` `}<inlineCode parentName="td">{`["key,"key"]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creates a multi-signature address and returns a json object.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
        </tr>
      </tbody>
    </table>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;