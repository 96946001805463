
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* KEYWORDS: multisig */
    }
    <h1>{`Using the multisig`}</h1>
    <p>{`You can access multisig graphic interface in latest builds of Peercoin-qt. Open Peercoin-qt, click on File - Multisig. Now you see Multisig interface.`}</p>
    <h3>{`How to create a multisig address using QT wallet`}</h3>
    <p>{`In this example, we'll go over creating 2/3 multisig address.
On your screen you see two tables, each containing "Public key", "Address" and "Label".`}</p>
    <p>{`1) First click "+ Add public key" button, this will make three input fields. Then enter number 2 in the "Required signatures" field.`}</p>
    <p>{`2) In the first table, we will first make input in "Address field". You can click book-like icon on the right side to load address from your address book. When you do so, other fields will be auto-populated.`}</p>
    <p>{`3) Your partners will need to do the same and copy their entries to you. When you exchange information properly make final check to reduce chance of error and finally click on "Create multisig address" button.`}</p>
    <p>{`4) Now multisig address is created, confirm that all of you got the same address.`}</p>
    <p>{`5) Finally click "Add address to wallet" in lower right to have it listed in your address book.`}</p>
    <p>{`That is it, now you have your 2/3 multisig address.`}</p>
    <h3>{`Creating a multisig address using the command line interface (debug console)`}</h3>
    <p>{`The multisig address is generated with the complete public keys of the participants.`}</p>
    <p><strong parentName="p">{`Alice:`}</strong></p>
    <p>{`validateaddress "mw2pj33HMhRfRkKtceHcyKpPiGYkPdD4SM"`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "isvalid" : true,
  "address" : "mw2pj33HMhRfRkKtceHcyKpPiGYkPdD4SM",
  "ismine" : true,
  "isscript" : false,
  "pubkey" : "02c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0b",
  "iscompressed" : true,
  "account" : "Alice"
}
`}</code></pre>
    <p><strong parentName="p">{`Bob:`}</strong></p>
    <p>{`validateaddress "mkLNecFNeiJgKjw6492nqDiQfKQs1JnLmE"`}</p>
    <pre><code parentName="pre" {...{}}>{` {
   "isvalid" : true,
   "address" : "mkLNecFNeiJgKjw6492nqDiQfKQs1JnLmE",
   "ismine" : true,
   "pubkey" : "025cc4b319284aabcdaef6e9a18af0bb73ac5d4b9f2556a214f30686b0173b316e",
   "iscompressed" : true,
   "account" : "Bob"
 }
`}</code></pre>
    <p><strong parentName="p">{`Charlie:`}</strong></p>
    <p>{`validateaddress "mm8Fwn92RU8zvJmH7TCpaYL3v4PTyjN4xd"`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "isvalid" : true,
  "address" : "mm8Fwn92RU8zvJmH7TCpaYL3v4PTyjN4xd",
  "ismine" : true,
  "isscript" : false,
  "pubkey" : "033ba42c942ff7e7fcf42ff604d6ef6c51826f9eea3a04308379c2ade98fb9e703",
  "iscompressed" : true,
  "account" : "Trent"
}
`}</code></pre>
    <p>{`createmultisig 2 '`}{`["02c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0b", "025cc4b319284aabcdaef6e9a18af0bb73ac5d4b9f2556a214f30686b0173b316e", "033ba42c942ff7e7fcf42ff604d6ef6c51826f9eea3a04308379c2ade98fb9e703"]`}{`'`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "address" : "2N582fRZZZm9hL4RH4sguG9SDDLZhu7eeng",
  "redeemScript" : "522102c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0b21025cc4b319284aabcdaef6e9a18af0bb73ac5d4b9f2556a214f30686b0173b316e21033ba42c942ff7e7fcf42ff604d6ef6c51826f9eea3a04308379c2ade98fb9e70353ae"
}
`}</code></pre>
    <hr></hr>
    <h3>{`How to spend from the multisig address`}</h3>
    <p>{`Reference Peercoin client is not capable of indexing the multisig addresses and showing their balance because multisig addresses can be owned by keys which are not part of the wallet (friends, family, backup). Thus the procedure to spend the funds from the multisig is a bit more complicated, more "low level" then usual.`}</p>
    <p>{`In the "Spend Funds" tab of the multisig dialog, on the left there is "Inputs" table. You need to provide with the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Unspent_transaction_output"
      }}>{`UTXO`}</a>{` you want to spend. That is, the `}<inlineCode parentName="p">{`txid`}</inlineCode>{` and output index. On the right side there is "Outputs" table, where the desired outputs will be placed. Please note that you have to include the change output and deduct the tx fee (0.01 PPC per kB).`}</p>
    <p>{`After that is set, click on the "Create Transaction" button bellow, and copy the resulting hash to your peers for further signing.
Finally paste the hash of fully signed raw transaction into "Sign Transaction" box bellow and click send.`}</p>
    <h3>{`Spending from the multisig via command line interface`}</h3>
    <p>{`Bob creates a transaction to spend the coins that Alice sent to the multisig address.
The transaction will have 360 PPC (Alice's coins) as input and 359.99 PPC as output (because of the mandatory 0.01 PPC transaction fee; the transaction won't be accepted by the network without it).`}</p>
    <p><strong parentName="p">{`Bob:`}</strong></p>
    <p>{`getrawtransaction "0ef16552d0dadaa150da34cfbc5380e82d59b5f328f967fb72104c43a1b99f74" 1`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "hex" : "01000000197f5a530105a302fe97c3ab33581486fdb39296e8728d2dac7b06324a62ab83515c30d9d8000000006b4830450221008054ee73403f401b2c10acdaed1e51e5345d35fb24574719d8a01203934c6e3202204d97e79ccdae05713b18d85e36896e3bd7a1a0636bd207a493779ca6a7c148c4012102c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0bffffffff02f0591b2c000000001976a9149f99f0ec7288694065243d32bea766bf9a8d602188ac002a75150000000017a914824524d69e3c8f2ea66e39af89727bc0e8d3de4b8700000000",
  "txid" : "0ef16552d0dadaa150da34cfbc5380e82d59b5f328f967fb72104c43a1b99f74",
  "version" : 1,
  "locktime" : 0,
  "vin" : [
    {
      "txid" : "d8d9305c5183ab624a32067bac2d8d72e89692b3fd86145833abc397fe02a305",
      "vout" : 0,
      "scriptSig" : {
        "asm" : "30450221008054ee73403f401b2c10acdaed1e51e5345d35fb24574719d8a01203934c6e3202204d97e79ccdae05713b18d85e36896e3bd7a1a0636bd207a493779ca6a7c148c401 02c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0b",
        "hex" : "4830450221008054ee73403f401b2c10acdaed1e51e5345d35fb24574719d8a01203934c6e3202204d97e79ccdae05713b18d85e36896e3bd7a1a0636bd207a493779ca6a7c148c4012102c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0b"
      },
      "sequence" : 4294967295
    }
  ],
  "vout" : [
    {
      "value" : 739.99000000,
      "n" : 0,
      "scriptPubKey" : {
        "asm" : "OP_DUP OP_HASH160 9f99f0ec7288694065243d32bea766bf9a8d6021 OP_EQUALVERIFY OP_CHECKSIG",
        "hex" : "76a9149f99f0ec7288694065243d32bea766bf9a8d602188ac",
        "reqSigs" : 1,
        "type" : "pubkeyhash",
        "addresses" : [
          "mv4r9a4FXuDgSA5GaFtLGC9W5Db3XJUrLD"
        ]
      }
    },
    {
      "value" : 360.00000000,
      "n" : 1,
      "scriptPubKey" : {
        "asm" : "OP_HASH160 824524d69e3c8f2ea66e39af89727bc0e8d3de4b OP_EQUAL",
        "hex" : "a914824524d69e3c8f2ea66e39af89727bc0e8d3de4b87",
        "reqSigs" : 1,
        "type" : "scripthash",
        "addresses" : [
          "2N582fRZZZm9hL4RH4sguG9SDDLZhu7eeng"
        ]
      }
    }
  ],
  "blockhash" : "05a7088c02f589ca91beb52ea9667955f7ee10a2433e56f2e11a058c2273bb70",
  "confirmations" : 2,
  "time" : 1398439709,
  "blocktime" : 1398439709
}
`}</code></pre>
    <p>{`createrawtransaction '`}{`[{"txid" : "0ef16552d0dadaa150da34cfbc5380e82d59b5f328f967fb72104c43a1b99f74", "vout" : 1, "scriptPubKey" : "a914824524d69e3c8f2ea66e39af89727bc0e8d3de4b87", "redeemScript" : "522102c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0b21025cc4b319284aabcdaef6e9a18af0bb73ac5d4b9f2556a214f30686b0173b316e21033ba42c942ff7e7fcf42ff604d6ef6c51826f9eea3a04308379c2ade98fb9e70353ae"}]`}{`' '{"mub5ke5cWP4nZW2VDgtAkFGA7UzSVhwese" : 359.99}'`}</p>
    <pre><code parentName="pre" {...{}}>{`0100000062815a5301749fb9a1434c1072fb67f928f3b5592de88053bccf34da50a1dadad05265f10e0100000000ffffffff01f0027515000000001976a9149a59a69866c7668acdd2b36491cfc18229d2348988ac00000000
`}</code></pre>
    <h4>{`First signature of the transaction`}</h4>
    <p>{`Bob signs the new transaction with the private key associated to the public key he used to create the multisig address and sends the result to Alice.`}</p>
    <p><strong parentName="p">{`Bob:`}</strong></p>
    <p>{`dumpprivkey "mkLNecFNeiJgKjw6492nqDiQfKQs1JnLmE"`}</p>
    <pre><code parentName="pre" {...{}}>{`cTxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxo5
`}</code></pre>
    <p>{`signrawtransaction "0100000062815a5301749fb9a1434c1072fb67f928f3b5592de88053bccf34da50a1dadad05265f10e0100000000ffffffff01f0027515000000001976a9149a59a69866c7668acdd2b36491cfc18229d2348988ac00000000" '`}{`[{"txid" : "0ef16552d0dadaa150da34cfbc5380e82d59b5f328f967fb72104c43a1b99f74", "vout" : 1, "scriptPubKey" : "a914824524d69e3c8f2ea66e39af89727bc0e8d3de4b87", "redeemScript" : "522102c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0b21025cc4b319284aabcdaef6e9a18af0bb73ac5d4b9f2556a214f30686b0173b316e21033ba42c942ff7e7fcf42ff604d6ef6c51826f9eea3a04308379c2ade98fb9e70353ae"}]`}{`' '`}{`["cTxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxo5"]`}{`'`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "hex" : "0100000062815a5301749fb9a1434c1072fb67f928f3b5592de88053bccf34da50a1dadad05265f10e01000000b40047304402201dfd957507d6b48b777a6f5c31d85fb8d00513b79c55dbd902c7f6dee90bc4cc0220773f05b481a9dbbc3153cb832acd994caef0d569de49d3b4a125b5f1e637836c014c69522102c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0b21025cc4b319284aabcdaef6e9a18af0bb73ac5d4b9f2556a214f30686b0173b316e21033ba42c942ff7e7fcf42ff604d6ef6c51826f9eea3a04308379c2ade98fb9e70353aeffffffff01f0027515000000001976a9149a59a69866c7668acdd2b36491cfc18229d2348988ac00000000",
  "complete" : false
}
`}</code></pre>
    <h4>{`Second signature of the transaction`}</h4>
    <p>{`Let's imagine that Alice refuses to sign the transaction.
Bob sends the transaction with one signature to Trent and asks him to validate it. Let's imagine that Trent decides that the transaction is legitimate.
Trent signs the transaction with the private key associated to the public key he used to create the multisig address.`}</p>
    <p><strong parentName="p">{`Charlie:`}</strong></p>
    <p>{`dumpprivkey "mm8Fwn92RU8zvJmH7TCpaYL3v4PTyjN4xd"`}</p>
    <pre><code parentName="pre" {...{}}>{`cPxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxis
`}</code></pre>
    <p>{`signrawtransaction "0100000062815a5301749fb9a1434c1072fb67f928f3b5592de88053bccf34da50a1dadad05265f10e01000000b40047304402201dfd957507d6b48b777a6f5c31d85fb8d00513b79c55dbd902c7f6dee90bc4cc0220773f05b481a9dbbc3153cb832acd994caef0d569de49d3b4a125b5f1e637836c014c69522102c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0b21025cc4b319284aabcdaef6e9a18af0bb73ac5d4b9f2556a214f30686b0173b316e21033ba42c942ff7e7fcf42ff604d6ef6c51826f9eea3a04308379c2ade98fb9e70353aeffffffff01f0027515000000001976a9149a59a69866c7668acdd2b36491cfc18229d2348988ac00000000" '`}{`[{"txid" : "0ef16552d0dadaa150da34cfbc5380e82d59b5f328f967fb72104c43a1b99f74", "vout" : 1, "scriptPubKey" : "a914824524d69e3c8f2ea66e39af89727bc0e8d3de4b87", "redeemScript" : "522102c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0b21025cc4b319284aabcdaef6e9a18af0bb73ac5d4b9f2556a214f30686b0173b316e21033ba42c942ff7e7fcf42ff604d6ef6c51826f9eea3a04308379c2ade98fb9e70353ae"}]`}{`' '`}{`["cPxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxis"]`}{`'`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "hex" : "0100000062815a5301749fb9a1434c1072fb67f928f3b5592de88053bccf34da50a1dadad05265f10e01000000fdfd000047304402201dfd957507d6b48b777a6f5c31d85fb8d00513b79c55dbd902c7f6dee90bc4cc0220773f05b481a9dbbc3153cb832acd994caef0d569de49d3b4a125b5f1e637836c014830450220576a38baba9c821a5dbfce6be50825322995041a052f8a76016928ee2741b542022100e95dcefd049628e8c422ee584eb1c3feab3d00f7111a29a16f40353ff7de9648014c69522102c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0b21025cc4b319284aabcdaef6e9a18af0bb73ac5d4b9f2556a214f30686b0173b316e21033ba42c942ff7e7fcf42ff604d6ef6c51826f9eea3a04308379c2ade98fb9e70353aeffffffff01f0027515000000001976a9149a59a69866c7668acdd2b36491cfc18229d2348988ac00000000",
  "complete" : true
}
`}</code></pre>
    <h4>{`Send the signed transaction to the network`}</h4>
    <p>{`The transaction has the two required signatures, anyone can send it to the network.`}</p>
    <p>{`sendrawtransaction "0100000062815a5301749fb9a1434c1072fb67f928f3b5592de88053bccf34da50a1dadad05265f10e01000000fdfd000047304402201dfd957507d6b48b777a6f5c31d85fb8d00513b79c55dbd902c7f6dee90bc4cc0220773f05b481a9dbbc3153cb832acd994caef0d569de49d3b4a125b5f1e637836c014830450220576a38baba9c821a5dbfce6be50825322995041a052f8a76016928ee2741b542022100e95dcefd049628e8c422ee584eb1c3feab3d00f7111a29a16f40353ff7de9648014c69522102c16ff447129fae7374d97212cf9fcd88a744da87ff2985869065cd6d17ee5c0b21025cc4b319284aabcdaef6e9a18af0bb73ac5d4b9f2556a214f30686b0173b316e21033ba42c942ff7e7fcf42ff604d6ef6c51826f9eea3a04308379c2ade98fb9e70353aeffffffff01f0027515000000001976a9149a59a69866c7668acdd2b36491cfc18229d2348988ac00000000"`}</p>
    <pre><code parentName="pre" {...{}}>{`fea9875ccac102897ff128c868027a05e3d2f9057569529c8e5e94f8d641bc47
`}</code></pre>
    <h2>{`How To Sign a Message Using the Peercoin Client`}</h2>
    <p>{`First, check that the address you want to use is loaded up into the wallet of your Peercoin client.  You can use the standard client.  The list of your addresses can be found using the receive tab.`}</p>
    <p><img parentName="p" {...{
        "src": "https://talk.peercoin.net/uploads/default/original/2X/3/3a72b5afa078a4d7b24cfaa7e2a5a303014ec6c6.jpeg",
        "alt": "1"
      }}></img></p>
    <p>{`Once you are satisfied that the address is in the wallet (you can even copy it to your clipboard if you like), open up the 'Sign message...' option of the 'File' menu.`}</p>
    <p><img parentName="p" {...{
        "src": "https://talk.peercoin.net/uploads/default/original/2X/3/3709984353eb778d29e1a91704b170b702383e59.jpeg",
        "alt": "2"
      }}></img></p>
    <p>{`At this point, you should be on the 'Sign Message' tab where you can paste in the address you want to use, either via your clipboard or by clicking on the address book button.`}</p>
    <p><img parentName="p" {...{
        "src": "https://talk.peercoin.net/uploads/default/original/2X/3/3b6c44045c17aab2a7694b571812d960da105975.jpeg",
        "alt": "3"
      }}></img></p>
    <p>{`Now you can type your message into the big blank box.  It might be best to copy and paste this text from a plaintext source in order to avoid any formatting issues.  When you are done, hit the 'Sign Message' button.`}</p>
    <p><img parentName="p" {...{
        "src": "https://talk.peercoin.net/uploads/default/original/2X/a/abc4f6835c2841fbd763f9985dcd15034005db50.jpeg",
        "alt": "4"
      }}></img></p>
    <p>{`If it completed its function correctly, it will display in green 'Messaged signed.'  Copy the signature using the convenient button.`}</p>
    <p><img parentName="p" {...{
        "src": "https://talk.peercoin.net/uploads/default/original/2X/8/8728853e3d051ebf766435752b6102ffcd8f960c.jpeg",
        "alt": "5"
      }}></img></p>
    <p>{`Just to be sure everything worked correctly, you can validate your signature.  Go to the 'Verify Message' tab and fill in all the fields.  If everything looks good, and it comes back with a green 'Message verified.' then you can share with anyone your signature, message, and address and they should be able to verify it just the same.`}</p>
    <p><img parentName="p" {...{
        "src": "https://talk.peercoin.net/uploads/default/original/2X/1/1f6ed2c60c32921af99e1c59cc27eb4124caaffa.jpeg",
        "alt": "6"
      }}></img></p>
    <p>{`Congratulations, you have now cryptographically signed and verified a message!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;